import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/src/components/mdxLayouts/PostsLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`How can I utilize Context in my app?`}</h2>
    <p>{`I will cover a couple of patterns that I found to be helpful when first learning Context. It can be tricky to pin when you will need it but, after you implement a context that works in your application flow you will see why context is great.`}</p>
    <h2>{`Database and Authentication`}</h2>
    <p>{`When you are making an application you will likely be using a database and authenticating users. In the past, I have gone through the slog of making separate folders and files for everything I need, but now I move towards Context. This allows you to write functions with easy to understand names and pull them into any part of your app. Here is an example of a simple login and sign up Context with Firebase.`}</p>
    <pre {...{
      "className": "grvsc-container quiet-light grvsc-ps-tuw09S",
      "data-language": "js",
      "data-index": "0"
    }}><code parentName="pre" {...{
        "className": "grvsc-code"
      }}><span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-10 grvsc-tuw09S-11"
            }}>{`import`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-7 grvsc-tuw09S-3"
            }}>{`React`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-1"
            }}>{`,`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-1"
            }}>{`{`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-7 grvsc-tuw09S-3"
            }}>{`useContext`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-1"
            }}>{`,`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-7 grvsc-tuw09S-3"
            }}>{`useEffect`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-1"
            }}>{`,`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-7 grvsc-tuw09S-3"
            }}>{`useState`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-1"
            }}>{`}`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-10 grvsc-tuw09S-11"
            }}>{`from`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-8"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-4 grvsc-tuw09S-8"
            }}>{`react`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-8"
            }}>{`"`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-10 grvsc-tuw09S-11"
            }}>{`import`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-1"
            }}>{`{`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-7 grvsc-tuw09S-3"
            }}>{`auth`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-1"
            }}>{`}`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-10 grvsc-tuw09S-11"
            }}>{`from`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-8"
            }}>{`"`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-4 grvsc-tuw09S-8"
            }}>{`../helpers/firebase`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-8"
            }}>{`"`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-7 grvsc-tuw09S-11"
            }}>{`const`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-7 grvsc-tuw09S-3"
            }}>{`AuthContext`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-15"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-7 grvsc-tuw09S-3"
            }}>{`React`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-1"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-9 grvsc-tZ0ymb-b grvsc-tuw09S-10"
            }}>{`createContext`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{`()`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-10 grvsc-tuw09S-11"
            }}>{`export`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-7 grvsc-tuw09S-11"
            }}>{`function`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-9 grvsc-tZ0ymb-b grvsc-tuw09S-10"
            }}>{`useAuth`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-1"
            }}>{`()`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-1"
            }}>{`{`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-10 grvsc-tuw09S-11"
            }}>{`return`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-9 grvsc-tZ0ymb-b grvsc-tuw09S-10"
            }}>{`useContext`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-7 grvsc-tuw09S-3"
            }}>{`AuthContext`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{`)`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-1"
            }}>{`}`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-10 grvsc-tuw09S-11"
            }}>{`export`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-7 grvsc-tuw09S-11"
            }}>{`function`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-9 grvsc-tZ0ymb-b grvsc-tuw09S-10"
            }}>{`AuthProvider`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-1"
            }}>{`({`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-7 grvsc-tuw09S-3"
            }}>{`children`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-1"
            }}>{`})`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-1"
            }}>{`{`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-7 grvsc-tuw09S-11"
            }}>{`const`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-1"
            }}>{`[`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-7 grvsc-tuw09S-3"
            }}>{`currentUser`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-1"
            }}>{`,`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-7 grvsc-tuw09S-3"
            }}>{`setCurrentUser`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-1"
            }}>{`]`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-15"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-9 grvsc-tZ0ymb-b grvsc-tuw09S-10"
            }}>{`useState`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{`()`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-7 grvsc-tuw09S-11"
            }}>{`const`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-1"
            }}>{`[`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-7 grvsc-tuw09S-3"
            }}>{`loading`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-1"
            }}>{`,`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-7 grvsc-tuw09S-3"
            }}>{`setLoading`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-1"
            }}>{`]`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-15"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-9 grvsc-tZ0ymb-b grvsc-tuw09S-10"
            }}>{`useState`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-5 grvsc-tuw09S-7"
            }}>{`true`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{`)`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-7 grvsc-tuw09S-11"
            }}>{`function`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-9 grvsc-tZ0ymb-b grvsc-tuw09S-10"
            }}>{`signup`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-1"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-7 grvsc-tuw09S-3"
            }}>{`email`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-1"
            }}>{`,`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-7 grvsc-tuw09S-3"
            }}>{`password`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-1"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-1"
            }}>{`{`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-10 grvsc-tuw09S-11"
            }}>{`return`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-7 grvsc-tuw09S-3"
            }}>{`auth`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-1"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-9 grvsc-tZ0ymb-b grvsc-tuw09S-10"
            }}>{`createUserWithEmailAndPassword`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-7 grvsc-tuw09S-3"
            }}>{`email`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-1"
            }}>{`,`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-7 grvsc-tuw09S-3"
            }}>{`password`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{`)`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-1"
            }}>{`}`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-7 grvsc-tuw09S-11"
            }}>{`function`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-9 grvsc-tZ0ymb-b grvsc-tuw09S-10"
            }}>{`login`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-1"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-7 grvsc-tuw09S-3"
            }}>{`email`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-1"
            }}>{`,`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-7 grvsc-tuw09S-3"
            }}>{`password`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-1"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-1"
            }}>{`{`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-10 grvsc-tuw09S-11"
            }}>{`return`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-7 grvsc-tuw09S-3"
            }}>{`auth`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-1"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-9 grvsc-tZ0ymb-b grvsc-tuw09S-10"
            }}>{`signInWithEmailAndPassword`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-7 grvsc-tuw09S-3"
            }}>{`email`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-1"
            }}>{`,`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-7 grvsc-tuw09S-3"
            }}>{`password`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{`)`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-1"
            }}>{`}`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-7 grvsc-tuw09S-11"
            }}>{`function`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-9 grvsc-tZ0ymb-b grvsc-tuw09S-10"
            }}>{`logout`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-1"
            }}>{`()`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-1"
            }}>{`{`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-10 grvsc-tuw09S-11"
            }}>{`return`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-7 grvsc-tuw09S-3"
            }}>{`auth`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-1"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-9 grvsc-tZ0ymb-b grvsc-tuw09S-10"
            }}>{`signOut`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{`()`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-1"
            }}>{`}`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-9 grvsc-tZ0ymb-b grvsc-tuw09S-10"
            }}>{`useEffect`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-1"
            }}>{`()`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-7 grvsc-tuw09S-11"
            }}>{`=>`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-1"
            }}>{`{`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-7 grvsc-tuw09S-11"
            }}>{`const`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-7 grvsc-tuw09S-3"
            }}>{`unsubscribe`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-15"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-7 grvsc-tuw09S-3"
            }}>{`auth`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-1"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-9 grvsc-tZ0ymb-b grvsc-tuw09S-10"
            }}>{`onAuthStateChanged`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-7 grvsc-tuw09S-3"
            }}>{`user`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-7 grvsc-tuw09S-11"
            }}>{`=>`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-1"
            }}>{`{`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{`      `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-9 grvsc-tZ0ymb-b grvsc-tuw09S-10"
            }}>{`setCurrentUser`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-7 grvsc-tuw09S-3"
            }}>{`user`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{`)`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{`      `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-9 grvsc-tZ0ymb-b grvsc-tuw09S-10"
            }}>{`setLoading`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{`(`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-5 grvsc-tuw09S-7"
            }}>{`false`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{`)`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-1"
            }}>{`}`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{`)`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-10 grvsc-tuw09S-11"
            }}>{`return`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-7 grvsc-tuw09S-3"
            }}>{`unsubscribe`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-1"
            }}>{`},`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{` [])`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-7 grvsc-tuw09S-11"
            }}>{`const`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-7 grvsc-tuw09S-3"
            }}>{`value`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-15"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-1"
            }}>{`{`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-7 grvsc-tuw09S-3"
            }}>{`currentUser`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-1"
            }}>{`,`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-7 grvsc-tuw09S-3"
            }}>{`signup`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-1"
            }}>{`,`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-7 grvsc-tuw09S-3"
            }}>{`login`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-1"
            }}>{`,`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-7 grvsc-tuw09S-3"
            }}>{`logout`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-1"
            }}>{`,`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-1"
            }}>{`}`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{`  `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-10 grvsc-tuw09S-11"
            }}>{`return`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{` (`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-20 grvsc-tuw09S-3"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-7 grvsc-tZ0ymb-b grvsc-tuw09S-13"
            }}>{`AuthContext.Provider`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-20 grvsc-tuw09S-12"
            }}>{` `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-11 grvsc-tZ0ymb-i grvsc-tuw09S-12"
            }}>{`value`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-15"
            }}>{`=`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-25"
            }}>{`{`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-7 grvsc-tuw09S-3"
            }}>{`value`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-25"
            }}>{`}`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-20 grvsc-tuw09S-3"
            }}>{`>`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-19 grvsc-tuw09S-12"
            }}>{`      `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-25"
            }}>{`{`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-15"
            }}>{`!`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-7 grvsc-tuw09S-3"
            }}>{`loading`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-25"
            }}>{` `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-15"
            }}>{`?`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-25"
            }}>{` `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-7 grvsc-tuw09S-3"
            }}>{`children`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-25"
            }}>{` `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-15"
            }}>{`:`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-25"
            }}>{` `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-5 grvsc-tuw09S-7"
            }}>{`null`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-25"
            }}>{`}`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-19 grvsc-tuw09S-12"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-20 grvsc-tuw09S-3"
            }}>{`</`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-7 grvsc-tZ0ymb-b grvsc-tuw09S-13"
            }}>{`AuthContext.Provider`}</span><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-20 grvsc-tuw09S-3"
            }}>{`>`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-1 grvsc-tuw09S-1"
            }}>{`  )`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "grvsc-tZ0ymb-6 grvsc-tuw09S-1"
            }}>{`}`}</span></span></span></code></pre>
    <p>{`This component wraps the root of the application and apply to all pages and components. This allows you to import the useAuth hook and return any values stored in Context. On a login page, you can import login and simply call login(email, password) and log a user in.`}</p>

    <style {...{
      "className": "grvsc-styles"
    }}>{`
  .grvsc-container {
    overflow: auto;
    position: relative;
    -webkit-overflow-scrolling: touch;
    padding-top: 1rem;
    padding-top: var(--grvsc-padding-top, var(--grvsc-padding-v, 1rem));
    padding-bottom: 1rem;
    padding-bottom: var(--grvsc-padding-bottom, var(--grvsc-padding-v, 1rem));
    border-radius: 8px;
    border-radius: var(--grvsc-border-radius, 8px);
    font-feature-settings: normal;
    line-height: 1.4;
  }
  
  .grvsc-code {
    display: table;
  }
  
  .grvsc-line {
    display: table-row;
    box-sizing: border-box;
    width: 100%;
    position: relative;
  }
  
  .grvsc-line > * {
    position: relative;
  }
  
  .grvsc-gutter-pad {
    display: table-cell;
    padding-left: 0.75rem;
    padding-left: calc(var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem)) / 2);
  }
  
  .grvsc-gutter {
    display: table-cell;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
  
  .grvsc-gutter::before {
    content: attr(data-content);
  }
  
  .grvsc-source {
    display: table-cell;
    padding-left: 1.5rem;
    padding-left: var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem));
    padding-right: 1.5rem;
    padding-right: var(--grvsc-padding-right, var(--grvsc-padding-h, 1.5rem));
  }
  
  .grvsc-source:empty::after {
    content: ' ';
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
  
  .grvsc-gutter + .grvsc-source {
    padding-left: 0.75rem;
    padding-left: calc(var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem)) / 2);
  }
  
  /* Line transformer styles */
  
  .grvsc-has-line-highlighting > .grvsc-code > .grvsc-line::before {
    content: ' ';
    position: absolute;
    width: 100%;
  }
  
  .grvsc-line-diff-add::before {
    background-color: var(--grvsc-line-diff-add-background-color, rgba(0, 255, 60, 0.2));
  }
  
  .grvsc-line-diff-del::before {
    background-color: var(--grvsc-line-diff-del-background-color, rgba(255, 0, 20, 0.2));
  }
  
  .grvsc-line-number {
    padding: 0 2px;
    text-align: right;
    opacity: 0.7;
  }
  
  .quiet-light { background-color: #F5F5F5; }
  .quiet-light .grvsc-tZ0ymb-i { font-style: italic; }
  .quiet-light .grvsc-tZ0ymb-b { font-weight: bold; }
  .quiet-light .grvsc-tZ0ymb-10 { color: #4B69C6; }
  .quiet-light .grvsc-tZ0ymb-1 { color: #333333; }
  .quiet-light .grvsc-tZ0ymb-7 { color: #7A3E9D; }
  .quiet-light .grvsc-tZ0ymb-6 { color: #777777; }
  .quiet-light .grvsc-tZ0ymb-4 { color: #448C27; }
  .quiet-light .grvsc-tZ0ymb-9 { color: #AA3731; }
  .quiet-light .grvsc-tZ0ymb-5 { color: #9C5D27; }
  .quiet-light .grvsc-tZ0ymb-20 { color: #91B3E0; }
  .quiet-light .grvsc-tZ0ymb-11 { color: #8190A0; }
  .quiet-light .grvsc-tZ0ymb-19 { color: #333333FF; }
  .quiet-light .grvsc-line-highlighted::before {
    background-color: var(--grvsc-line-highlighted-background-color, rgba(0, 0, 0, 0.05));
    box-shadow: inset var(--grvsc-line-highlighted-border-width, 4px) 0 0 0 var(--grvsc-line-highlighted-border-color, rgba(0, 0, 0, 0.2));
  }
  body[class=dark] .grvsc-ps-tuw09S {
    background-color: #1e1e1e;
    color: #c5c8c6;
  }
  body[class=dark] .grvsc-ps-tuw09S .grvsc-tuw09S-11 { color: #9872A2; }
  body[class=dark] .grvsc-ps-tuw09S .grvsc-tuw09S-1 { color: #C5C8C6FF; }
  body[class=dark] .grvsc-ps-tuw09S .grvsc-tuw09S-3 { color: #6089B4; }
  body[class=dark] .grvsc-ps-tuw09S .grvsc-tuw09S-8 { color: #9AA83A; }
  body[class=dark] .grvsc-ps-tuw09S .grvsc-tuw09S-15 { color: #676867; }
  body[class=dark] .grvsc-ps-tuw09S .grvsc-tuw09S-10 { color: #CE6700; }
  body[class=dark] .grvsc-ps-tuw09S .grvsc-tuw09S-7 { color: #408080; }
  body[class=dark] .grvsc-ps-tuw09S .grvsc-tuw09S-13 { color: #C7444A; }
  body[class=dark] .grvsc-ps-tuw09S .grvsc-tuw09S-12 { color: #D0B344; }
  body[class=dark] .grvsc-ps-tuw09S .grvsc-tuw09S-25 { color: #C5C8C6; }
  body[class=dark] .grvsc-ps-tuw09S .grvsc-line-highlighted::before {
    background-color: var(--grvsc-line-highlighted-background-color, rgba(255, 255, 255, 0.1));
    box-shadow: inset var(--grvsc-line-highlighted-border-width, 4px) 0 0 0 var(--grvsc-line-highlighted-border-color, rgba(255, 255, 255, 0.5));
  }
`}</style>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      